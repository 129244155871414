<template>
    <div class="section bg-gray-100 border border-gray-400 p-2 mx-48 mt-4">
        <div class="mx-6">
            <div class="flex flex-1 overflow-auto justify-between mt-2 ">
                <p class="font-bold">lease overview</p>
                <div>
                    <icon name="checkcircle" class="inline-block w-3 h-3 mr-1 text-green-400"></icon> 
                    <p class="inline-block text-black"> active</p>
                </div>
            
            </div>
            <div class=" mt-4 mb-2 border-gray-40000 border-t-2">
                <div class="flex flex-1 overflow-auto my-5">
                    <icon name="checkmark" class="w-5 h-5 text-green-400 mr-2" /> 
                    <icon name="user2" class="w-5 h-5 mr-2" /> 
                    <p class="ml-1">Dan Rogers</p>  <span class="border-gray-400 border-2 px-3 ml-10">resident</span>
                </div>
                <div class="flex flex-1 overflow-auto my-5">
                    <icon name="checkmark" class="w-5 h-5 text-green-400 mr-2" /> 
                    <icon name="user2" class="w-5 h-5 mr-2" /> 
                    <p class="ml-1">Dan Rogers</p>  <span class="border-gray-400 border-2 px-3 ml-10">resident</span>
                </div>
                <div class="flex flex-1 overflow-auto my-5">
                    <icon name="checkmark" class="w-5 h-5 text-green-400 mr-2" /> 
                    <icon name="user2" class="w-5 h-5 mr-2" /> 
                    <p class="ml-1">Dan Rogers</p>  <span class="border-gray-400 border-2 px-3 ml-10">resident</span>
                </div>

                <div class="grid grid-flow-col grid-cols-2 grid-rows-3 gap-5 mt-10">
                    <div>
                        <p class="text-gray-500 font-bold">unit</p>
                        <p class="text-black">332</p>
                    </div>
                    <div>
                        <p class="text-gray-500 font-bold">leases start date</p>
                        <p class="text-black">01/22/21</p>
                    </div>
                    <div>
                        <p class="text-gray-500 font-bold">move in date</p>
                        <p class="text-black">01/22/21</p>
                    </div>
                    <div>
                        <p class="text-gray-500 font-bold">lease term</p>
                        <p class="text-black">12 months</p>
                    </div>
                    <div>
                        <p class="text-gray-500 font-bold">leases end date</p>
                        <p class="text-black">01/21/22</p>
                    </div>
                </div>
            </div>
            <div class=" mt-4 mb-2 border-gray-40000 border-t-2">
                <div class="mt-5">
                    <p class="mb-2">one-time charges and credits</p>
                    <p class="mb-2">recurring charges and credits</p>
                </div>
              
            </div>
        </div>    
       
        <modal-footer :footer="footer" :primary="save" :secondary="cancel"/>
           
    </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import ModalFooter from '@/components/ui/modals/ModalFooter'


import Icon from "@/components/ui/Icon";

export default ({
    mixins: [NotifyMixin, AuthMixin, ModalNavigation,],
    components: {
        // Loader,
        Icon,
        ModalFooter
    },
    data() {
        return {
              footer: {
                primaryButton: 'submit move in',
                secondaryButton: 'back',
            },
        }
    },
    methods: {
        save(){
            this.$router.push({name: 'bookmarks'});
        },
        cancel(){
            this.$router.push({name: 'leases.costs'});
        },
    }
})
</script>